import React, { useState } from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import Page from '@lib/components/v2/Page';
import { Checkbox } from '@lib/components/v2/Form';
import { localizedString } from '@languages';

import parse from 'html-react-parser';
import './Privacy.style.scss';

const Privacy = ({ privacyContent, onAccept, onSelectLanguage, hidePrivacyCheckBox }) => {
  const [isPrivacyAccepted, setIsPrivacyAccepted] = useState(false);
  const { backurl } = document.body.dataset;

  const isContinueButtonEnabled = hidePrivacyCheckBox || isPrivacyAccepted;
  const buttons = [
    {
      label: localizedString('continue'),
      disabled: !isContinueButtonEnabled,
      onClick: () => {
        if (isContinueButtonEnabled) {
          onAccept();
        }
      },
      dataTestId: 'privacy-continue'
    }
  ];

  if (backurl) {
    buttons.unshift({
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => {
        window.location.href = backurl;
      }
    });
  }

  let privacyContentElements;
  if (!privacyContent) {
    const privacyContents = localizedString('privacy.flowV2Content') || [];
    privacyContentElements = privacyContents.map((item, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index} data-testid={`privacy-consent-${index}`}>
          {parse(item)}
          <br />
          <br />
        </div>
      );
    });
  } else {
    privacyContentElements = isString(privacyContent) ? parse(privacyContent) : privacyContent;
  }

  return (
    <Page
      isPrivacy
      buttons={buttons}
      className="privacy-flow-v2-wrapper"
      onSelectLanguage={onSelectLanguage}
    >
      <div className="heading" data-testid="privacy-heading">
        {localizedString('privacy.flowV2Title')}
      </div>
      <div className="content">
        {privacyContentElements}

        {!hidePrivacyCheckBox && (
          <Checkbox
            id="confirm"
            value={isPrivacyAccepted}
            onChange={(value) => setIsPrivacyAccepted(value)}
            label={localizedString('privacy.flowV2CheckBox')}
            dataTestId="privacy-chkBx"
            labelDataTestId="privacy-consentTxt"
          />
        )}
      </div>
    </Page>
  );
};

Privacy.propTypes = {
  onAccept: PropTypes.func,
  privacyContent: PropTypes.any,
  onSelectLanguage: PropTypes.func,
  hidePrivacyCheckBox: PropTypes.bool
};

export default Privacy;
