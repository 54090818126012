import React from 'react';
import PropTypes from 'prop-types';

import { localizedString } from '@languages';
import classes from './LoadingSpinner.style.module.scss';

const LoadingSpinner = ({ heading, showTitle }) => {
  return (
    <div className={classes.wrapper}>
      <div>
        <div className={classes.spinner1} />
        <div className={classes.heading}>{heading}</div>
        <div className={classes.title}>
          {localizedString('thisMayTakeAFewSeconds')}
          <br />
          <br />
          {showTitle && localizedString('nextWeWillAskIfTheInformationShownMatchesYourPhotoID')}
        </div>
      </div>
    </div>
  );
};

LoadingSpinner.propTypes = {
  heading: PropTypes.string,
  showTitle: PropTypes.bool
};

LoadingSpinner.defaultProps = {
  heading: localizedString('pleaseWait'),
  showTitle: false
};

export default LoadingSpinner;
