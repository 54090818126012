import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import parse from 'html-react-parser';
import { format } from 'react-string-format';
import Animation, { VOI_V2_ANIMATION_FILE_URLS } from '@components/Animation';
import { LoadingBar } from '../..';
import classes from './IdSelection.style.module.scss';

const { ENABLE_ONE_DOC_CONDITION = false } = process.env;

const PORTION_TITLE = 'title';
const PORTION_DESCRIPTION = 'description';

const getContentsForCapture = (documentType, portion) => {
  if (documentType.match(/LICENCE/i)) {
    if (portion === PORTION_TITLE) {
      return 'Capture your I.D';
    }
    if (portion === PORTION_DESCRIPTION) {
      return 'Take a photo of your I.D making sure the whole card is visible in the image.';
    }
  } else if (documentType.match(/PASSPORT/i)) {
    if (portion === PORTION_TITLE) {
      return 'Capture your passport';
    }
    if (portion === PORTION_DESCRIPTION) {
      return 'Take a photo of your passport making sure the whole card is visible in the image.';
    }
  } else if (documentType === 'PROOF_OF_AGE') {
    if (portion === PORTION_TITLE) {
      return 'Capture your I.D';
    }
    if (portion === PORTION_DESCRIPTION) {
      return 'Take a photo of your I.D making sure the whole card is visible in the image.';
    }
  } else if (documentType.match(/MEDICARE/i)) {
    if (portion === PORTION_TITLE) {
      return 'Capture your I.D';
    }
    if (portion === PORTION_DESCRIPTION) {
      return 'Take a photo of your I.D making sure the whole card is visible in the image.';
    }
  } else if (documentType === 'VETERAN_AFFAIRS_CARD') {
    if (portion === PORTION_TITLE) {
      return 'Capture your I.D';
    }
    if (portion === PORTION_DESCRIPTION) {
      return 'Take a photo of your I.D making sure the whole card is visible in the image.';
    }
  }
  return '';
};

const IdSelection = ({ isLoading = false, progressBar, selectedCard = {} }) => {
  const animationMap = {
    PASSPORT: VOI_V2_ANIMATION_FILE_URLS.PASSPORT,
    AUS_AUTO_BIRTH: VOI_V2_ANIMATION_FILE_URLS.CERTIFICATES,
    CITIZENSHIP_CERT: VOI_V2_ANIMATION_FILE_URLS.CERTIFICATES,
    AUS_CERT_DESCENT: VOI_V2_ANIMATION_FILE_URLS.CERTIFICATES
  };

  const {
    VOI_FLOW_V2_CAPTURE_TITLE_FORMAT = 'Capture your {0}',
    VOI_FLOW_V2_CAPTURE_DESC_FORMAT = 'Follow the tutorial to make sure you capture your {0} properly',
    VOI_FLOW_V2_LOADING_DETAILS_HEADING = `Verifying your details`,
    VOI_FLOW_V2_LOADING_DETAILS_DESCRIPTION = 'Next, please check carefully that your details match your ID documents.'
  } = process.env;

  const NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING = VOI_FLOW_V2_LOADING_DETAILS_HEADING.replace(
    '{idType}',
    selectedCard.title
  );

  let heading = format(VOI_FLOW_V2_CAPTURE_TITLE_FORMAT, selectedCard.title);
  let description =
    VOI_FLOW_V2_CAPTURE_DESC_FORMAT === ''
      ? ''
      : format(VOI_FLOW_V2_CAPTURE_DESC_FORMAT, selectedCard.title);

  let reviewHeading = NEW_VOI_FLOW_V2_LOADING_DETAILS_HEADING;
  let reviewDescription = parse(VOI_FLOW_V2_LOADING_DETAILS_DESCRIPTION);

  if (ENABLE_ONE_DOC_CONDITION) {
    if (selectedCard && selectedCard.type) {
      const { type } = selectedCard;
      heading = getContentsForCapture(type, PORTION_TITLE);
      description = getContentsForCapture(type, PORTION_DESCRIPTION);
    }

    reviewHeading = 'Reviewing your ID data';
    reviewDescription =
      'On the next screen, please check carefully that all the data shown matches your card exactly and edit it if needed.';
  }

  return (
    <div style={{ height: '100%' }}>
      {!isLoading && (
        <div className={classes.wrapper}>
          <div className={classes.heading} data-testid="docs-heading">
            {heading}
          </div>
          <div className={classes.description} data-testid="docs-txt">
            {description}
          </div>
          <div className={classes['animation-container']} data-testid="docs-anima">
            <Animation
              animationUrl={animationMap[selectedCard.type] || VOI_V2_ANIMATION_FILE_URLS.CARD}
            />
          </div>
        </div>
      )}

      {isLoading && (
        <div className={classes.wrapper}>
          <div className={classnames(classes.heading)} data-testid="view-heading">
            {reviewHeading}
          </div>
          <div className={classnames(classes.description)} data-testid="view-instruction">
            {reviewDescription}
          </div>
          <div className={classes['animation-container']} data-testid="view-anima">
            <Animation animationUrl={VOI_V2_ANIMATION_FILE_URLS.REVIEW} />
          </div>
          <LoadingBar width={progressBar} />
        </div>
      )}
    </div>
  );
};

IdSelection.propTypes = {
  isLoading: PropTypes.bool,
  progressBar: PropTypes.number,
  selectedCard: PropTypes.object
};

export default IdSelection;
