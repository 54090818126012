/* eslint-disable camelcase */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormValidation } from 'calidation';
import Modal from '@lib/components/v2/Modal';
import { SendSMSContent } from '@components/Contents';
import { localizedString } from '@languages';
import SMS from '../../services/SMS';
import FormModel from './SendSMS.form';

class SendSMS extends Component {
  constructor(props) {
    super(props);

    this.state = {
      phone_code: '64',
      phone_number: '',
      isLoading: false,
      message: null,
      error: null,
      help: false,
      resendCount: 0
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleHelp = this.handleHelp.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { resendCount } = this.props;
    const { phone: phone_number } = document.body.dataset;
    this.setState({ phone_number, resendCount });
  }

  /**
   * Send an SMS message.
   * @return {Void}
   */
  handleSubmit({ isValid }) {
    const { phone_number, phone_code, resendCount } = this.state;
    if (isValid) {
      this.setState({ isLoading: true, error: null, message: null });
      SMS.send({ phone_number, phone_code })
        .then(({ success }) => {
          this.setState({ isLoading: false });
          if (success) {
            this.setState({
              message: localizedString('messageSentSuccessfully'),
              resendCount: resendCount + 1
            });
          } else {
            this.setState({
              error: localizedString('errorSendingSms')
            });
          }
        })
        .catch(() =>
          this.setState({
            error: localizedString('refreshAndTryAgain'),
            isLoading: false
          })
        );
    }
  }

  /**
   * Handle the cancel button.
   * @return {Void}
   */
  // eslint-disable-next-line class-methods-use-this
  handleCancel() {
    const backurl = document.querySelector('body').getAttribute('data-backurl');
    if (backurl) {
      window.location.href = backurl;
    } else {
      window.history.back();
    }
  }

  /**
   * Handle the help button.
   * @return {Void}
   */
  handleHelp(e) {
    e.preventDefault();
    this.setState({ help: true });
  }

  /**
   * On mobile phone field change.
   * @return {Void}
   */
  handleChange(data) {
    const { phone_code, phone_number } = data;
    this.setState({
      phone_code,
      phone_number
    });
  }

  render() {
    const { COUNTRY, SELECT_COUNTRIES } = process.env;

    const { ipCountryCode } = this.props;

    const countryCode =
      !isEmpty(SELECT_COUNTRIES) && SELECT_COUNTRIES.includes(ipCountryCode)
        ? ipCountryCode
        : COUNTRY;

    const { isLoading, message, error, help, resendCount } = this.state;
    const { phone } = document.body.dataset;
    const modalBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ message: null, error: null, help: false })
      }
    ];

    return (
      <div>
        <Modal
          small
          heading=""
          success={error === null}
          isOpen={message !== null || error !== null}
          buttons={modalBtns}
        >
          {message && (
            <h3 style={{ textAlign: 'center' }} className="b-title">
              {localizedString('smsSentToMobilePleaseCheck1')}
              <br />
              <br />
              {localizedString('smsSentToMobilePleaseCheck2')}
            </h3>
          )}
        </Modal>
        <Modal small issue={null} isOpen={help} buttons={modalBtns}>
          <div style={{ textAlign: 'center' }}>{localizedString('pleaseCheckAndTryAgain')}</div>
        </Modal>
        <FormValidation
          initialValues={{ phone_number: phone, phone_code: '+64' }}
          onSubmit={this.handleSubmit}
          config={FormModel}
        >
          {({ errors, submitted }) => (
            <SendSMSContent
              country={countryCode}
              ipCountry={countryCode}
              resendCount={resendCount}
              onCancel={this.handleCancel}
              onChange={this.handleChange}
              onHelp={this.handleHelp}
              isLoading={isLoading}
              errors={submitted ? errors : {}}
            />
          )}
        </FormValidation>
      </div>
    );
  }
}

SendSMS.propTypes = {
  resendCount: PropTypes.number,
  ipCountryCode: PropTypes.string
};

export default connect(mapStateToProps, null)(SendSMS);

function mapStateToProps({ ipInfo }) {
  return {
    ipCountryCode: ipInfo.ipCountryCode
  };
}
