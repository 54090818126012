import React from 'react';
import { localizedString } from '@languages';
import Message from '../Message';

export const DigitalDLTimeLimitError = (props) => {
  return (
    <Message {...props} title={localizedString('pleaseTryAgain')} issue>
      {localizedString('screenshotWithin')}
    </Message>
  );
};
