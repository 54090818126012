import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Input.style.module.scss';

class Input extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    hasError: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
    inputRef: PropTypes.func,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    type: PropTypes.oneOf([
      'date',
      'datetime-local',
      'email',
      'month',
      'number',
      'password',
      'search',
      'tel',
      'text',
      'textarea',
      'time',
      'url',
      'week',
      'hidden'
    ]),
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    placeholder: PropTypes.string
  };

  static defaultProps = {
    type: 'text'
  };

  constructor(props) {
    super(props);
    const { type } = this.props;
    this.inputRef = React.createRef();
    this.isTextarea = type === 'textarea';
    this.state = {
      placeH: ' ',
      cursor: null
    };
  }

  componentDidUpdate() {
    const { cursor } = this.state;
    if (this.inputRef && this.inputRef.current && !this.isTextarea && cursor !== null) {
      this.inputRef.current.setSelectionRange(cursor, cursor);
    }
  }

  handleFocus = () => {
    const { placeholder } = this.props;
    this.setState({
      placeH: placeholder
    });
  };

  handleBlur = () => {
    this.setState({
      placeH: ' ',
      cursor: null
    });
  };

  render() {
    const {
      id,
      label,
      className,
      disabled,
      readOnly,
      onChange,
      required,
      inputRef,
      type,
      value,
      defaultValue,
      hasError
    } = this.props;

    this.inputRef = inputRef || this.inputRef;

    const { placeH } = this.state;

    const Element = this.isTextarea ? 'textarea' : 'input';

    const handleChange = (e) => {
      if (disabled || readOnly || !onChange) {
        return;
      }
      this.setState({ cursor: e.target.selectionStart });
      onChange(e.target.value);
    };

    return (
      <div
        className={classNames(
          'inputWrapper',
          classes.inputWrapper,
          className,
          hasError && classes.hasError
        )}
      >
        <Element
          id={id}
          name={id}
          autoComplete="none"
          disabled={disabled}
          required={required}
          onChange={handleChange}
          readOnly={readOnly}
          placeholder={placeH || ' '}
          ref={this.inputRef}
          type={this.isTextarea ? null : type}
          value={value}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          defaultValue={defaultValue}
        />
        <label htmlFor={id}>{label}</label>
        {hasError && <div className={classes.error}>{hasError}</div>}
      </div>
    );
  }
}

export default Input;
