import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import classes from './Checkbox.style.module.scss';

const Checkbox = ({ id, className, label, onChange, value, required }) => {
  const handleChange = (e) => (!onChange ? null : onChange(e.target.checked));
  return (
    <div className={classNames(classes.checkbox, className)}>
      <input
        type="checkbox"
        id={id}
        onChange={handleChange}
        defaultChecked={value}
        required={required}
      />
      <label
        style={{
          fontSize: label && label.length >= 30 && '14px',
          lineHeight: label && label.length >= 30 && '20px'
        }}
        htmlFor={id}
        className="custom-black-labels-if-needed"
      >
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.bool,
  required: PropTypes.bool
};

Checkbox.defaultProps = {
  value: false,
  className: '',
  label: ''
};

export default Checkbox;
