import { localizedString } from '@languages';

const { DL_NUMBER_VALIDATION = true } = process.env;

export const firstName = {
  firstName: {
    isRequired: localizedString('pleaseEnterFirstName')
    /* isRegexMatch: {
            message: localizedString("lettersAndSymbolsOnly"),
            regex: /^([a-zA-Z `‘’'-]+)$/
        }, */
  }
};

export const middleName = {
  middleName: {
    /* isRegexMatch: {
            message: localizedString("lettersAndSymbolsOnly"),
            regex: /^([a-zA-Z `‘’'-]+)$/
        } */
  }
};

export const lastName = {
  lastName: {
    isRequired: localizedString('verifyDetails.VERIFY_DETAILS_MESSAGE_LAST_NAME')
    /* isRegexMatch: {
            message: localizedString("lettersAndSymbolsOnly"),
            regex: /^([a-zA-Z `‘’'-]+)$/
        } */
  }
};

export const dateOfBirth = {
  dateOfBirth: {
    isRequired: localizedString('pleaseEnterDateOfBirth'),
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9-]+)$/
    }
  }
};

export const expiryDate = {
  expiryDate: {
    isRequired: localizedString('pleaseEnterDateOfExpiry'),
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9-]+)$/
    }
  }
};

export const visaStartDate = {
  visaStartDate: {
    isRequired: localizedString('pleaseEnterVisaStartDate'),
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9/]+)$/
    }
  }
};

export const visaEndDate = {
  visaEndDate: {
    isRequired: localizedString('pleaseEnterVisaEndDate'),
    isRegexMatch: {
      message: localizedString('invalidDate'),
      regex: /^([0-9/]+)$/
    }
  }
};

export const licenceNumber = {
  licenceNumber: {
    isRequired: localizedString('pleaseEnterLicenceNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)/
    },
    isExactLength: {
      message: localizedString('licenceNumberMustBe8Chars'),
      length: 8
    }
  }
};
if (!DL_NUMBER_VALIDATION) {
  delete licenceNumber.licenceNumber.isExactLength;
}

export const licenceVersion = {
  licenceVersion: {
    isRequired: localizedString('pleaseEnterVersionNumber'),
    isRegexMatch: {
      message: localizedString('numbersOnly'),
      regex: /^([0-9]+)$/
    },
    isExactLength: {
      message: localizedString('versionNumberMustBe3Numbers'),
      length: 3
    }
  }
};

export const passportNumber = {
  passportNumber: {
    isRequired: localizedString('pleaseEnterPassportNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    },
    isMaxLength: {
      message: localizedString('alphaNumeric9DigitsMaximum'),
      length: 9
    }
  }
};

export const cardNumber = {
  cardNumber: {
    isRequired: localizedString('pleaseEnterCardNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    }
  }
};

export const idNumber = {
  idNumber: {
    isRequired: localizedString('pleaseEnterCardNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    }
  }
};

export const medicareNumber = {
  medicareNumber: {
    isRequired: localizedString('pleaseEnterMedicareNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndNumbersOnly'),
      regex: /^([a-zA-Z0-9]+)$/
    },
    isMaxLength: {
      message: localizedString('max10Chars'),
      length: 10
    }
  }
};

export default {
  firstName,
  lastName,
  middleName,
  dateOfBirth,
  expiryDate,
  visaStartDate,
  visaEndDate,
  licenceNumber,
  licenceVersion,
  passportNumber,
  cardNumber,
  idNumber,
  medicareNumber
};
