import React from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import App from './views/App';

const { UNIVERSAL_URL_PATH = '/verify' } = process.env;

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <div>
        <Route exact path="/" component={App} />
        <Route exact path={UNIVERSAL_URL_PATH} component={App} />
      </div>
    </Router>
  </Provider>,
  document.getElementById('root')
);
