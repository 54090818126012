import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useLogo } from '@js/hooks/useLogo';
import classes from './Header.style.module.scss';

const Header = ({ hideLogo, showClose, onClose, isDark, onSelectLanguage }) => {
  const { MULTI_LANGUAGE } = process.env;
  const finalLogo = useLogo();

  return (
    <header className={classNames(classes.warpper, 'clearfix', isDark && classes.isDark)}>
      {!hideLogo && (
        <div className={classes['img-container']}>
          <img alt="" src={finalLogo} />
        </div>
      )}

      {showClose && (
        <div className={classes['close-container']}>
          <svg
            onClick={onClose}
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M1 1L17 17" stroke="#424876" strokeWidth="2" strokeLinecap="round" />
            <path d="M17.001 1L1.00098 17" stroke="#424876" strokeWidth="2" strokeLinecap="round" />
          </svg>
        </div>
      )}

      {MULTI_LANGUAGE && onSelectLanguage && (
        <button
          type="button"
          className={isDark ? classes['langButton-white'] : classes['langButton-black']}
          onClick={onSelectLanguage}
          data-testid="header-language"
        />
      )}
    </header>
  );
};

Header.propTypes = {
  /* If its `true` the header position will be fixed */
  isDark: PropTypes.bool,
  hideLogo: PropTypes.bool,
  showClose: PropTypes.bool,
  onClose: PropTypes.func,
  onSelectLanguage: PropTypes.func
};

Header.defaultProps = {
  hideLogo: false,
  showClose: false,
  onClose: () => {}
};

export default Header;
