import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { wrapperClasses } from '@lib/Utils';
import Header from '../Header';
import { Button, ButtonGroup } from '..';
import classes from './Page.style.module.scss';

class Page extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    footerContent: PropTypes.node,
    footerShadow: PropTypes.bool,
    hideLogo: PropTypes.bool,
    showClose: PropTypes.bool,
    isDark: PropTypes.bool,
    isMessage: PropTypes.bool,
    onClose: PropTypes.func,
    onSelectLanguage: PropTypes.func,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
        shadow: PropTypes.bool,
        large: PropTypes.bool
      })
    )
  };

  static defaultProps = {
    className: '',
    buttons: [],
    footerShadow: false,
    hideLogo: false,
    showClose: false,
    isDark: false,
    isMessage: false,
    onClose: () => {}
  };

  constructor(props) {
    super(props);
    this.state = {
      isPageScrollable: false
    };
  }

  componentDidMount() {
    const { footerShadow, buttons } = this.props;
    const height = this.pageContent.clientHeight;
    let scrollHeight = 0;
    const { children } = this.pageContent;
    for (let i = 0; i < children.length; i++) {
      const child = children[i];
      scrollHeight += child.clientHeight;
      // console.log(child, child.offsetHeight)
    }
    let val = height < 83 + 129 + 372;
    if (scrollHeight < height) {
      val = false;
    }
    if (footerShadow) {
      val = true;
    }

    this.setState({
      isPageScrollable: val && buttons.length > 0
    });
  }

  render() {
    const { isPageScrollable: footerShadow } = this.state;
    const {
      children,
      footerContent,
      className,
      buttons,
      hideLogo,
      showClose,
      onClose,
      isDark,
      isMessage,
      onSelectLanguage
    } = this.props;

    return (
      <div
        className={classNames(
          classes.wrapper,
          className && wrapperClasses(classes, className),
          isDark && classes.isDark
        )}
      >
        <div
          ref={(pageContent) => {
            this.pageContent = pageContent;
          }}
          className={classes.body}
        >
          <Header
            onSelectLanguage={onSelectLanguage}
            isDark={isDark}
            hideLogo={hideLogo || isDark}
            showClose={showClose}
            onClose={onClose}
          />
          {children}
        </div>
        {(!isMessage || footerContent || (buttons && buttons.length > 0)) && (
          <div
            className={classNames(
              classes.footer,
              footerShadow && classes.footerShadow,
              isDark && classes.isDark
            )}
          >
            {footerContent}
            {buttons.length > 0 && (
              <ButtonGroup btnCount={buttons.length} stacked>
                {buttons.map((button) => (
                  <Button key={button.label} {...button} />
                ))}
              </ButtonGroup>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default Page;
