import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { localizedString } from '@languages';
import classes from './Button.style.module.scss';

/**
 * A sample button.
 */
const Button = ({
  label,
  children,
  type,
  shadow,
  large,
  full,
  variant,
  className,
  loading,
  onClick,
  disabled,
  rounded
}) => {
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={classNames(
        classes.btn,
        className,
        rounded && classes.rounded,
        shadow && classes.shadow,
        large && classes.large,
        full && classes.full,
        classes[variant],
        variant === 'secandary' && 'btn-secondary-override'
      )}
    >
      {loading ? localizedString('loading') : label || children}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  shadow: PropTypes.bool,
  rounded: PropTypes.bool,
  /** Large Button */
  large: PropTypes.bool,
  /** Full with */
  full: PropTypes.bool,
  /** Use 'outline' or 'solid'. Other values are deprecated */
  variant: PropTypes.oneOf(['outline', 'primary', 'secandary', 'transparent']),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string
};

Button.defaultProps = {
  label: '',
  type: 'button',
  shadow: false,
  large: false,
  full: false,
  variant: 'secandary',
  className: '',
  loading: false,
  rounded: true,
  onClick: () => null
};

export default Button;
