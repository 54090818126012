import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './ButtonGroup.style.module.scss';

/**
 * A wrapper to handle buttons.
 */
const ButtonGroup = ({ children, stacked, btnCount }) => {
  return (
    <div style={{ padding: stacked && '20px 0' }} className={classes.wrapper}>
      <div
        className={classNames(btnCount > 1 && classes.buttons, btnCount === 1 && classes.btnRight)}
      >
        {children}
      </div>
    </div>
  );
};

ButtonGroup.propTypes = {
  children: PropTypes.node,
  btnCount: PropTypes.number,
  stacked: PropTypes.bool
};

ButtonGroup.defaultProps = {
  stacked: false
};

export default ButtonGroup;
