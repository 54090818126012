import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactSwipe from 'react-swipe';
import classes from './Tips.style.module.scss';

class Tips extends Component {
  static propTypes = {
    idType: PropTypes.string,
    onCapture: PropTypes.func.isRequired,
    display: PropTypes.string,
    tips: PropTypes.object
  };

  static defaultProps = {
    idType: 'DL',
    tips: []
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0
    };

    this.isComponentMounted = false;
    this.reactSwipe = null;

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.isComponentMounted = true;
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  /**
   * Handle the next button
   * @return {Void}
   */
  handleClick() {
    const { step } = this.state;
    const { tips, onCapture } = this.props;

    if (step < tips.length - 1) {
      this.reactSwipe.next();
    } else {
      onCapture();
    }
  }

  render() {
    const { step } = this.state;
    const { tips, display, idType } = this.props;

    const swipeOptions = {
      continuous: false,
      callback: (step) => {
        if (this.isComponentMounted) {
          this.setState({ step });
        }
      }
    };

    let path = `images/tips/SHORT_FLOW`;
    let shortPath = 'PASSPORT';

    if (idType.includes('DRIVERLICENCE')) {
      path = `${path}/DL`;
      shortPath = 'DL';
    } else if (idType === 'PASSPORT') {
      path = `${path}/PASSPORT`;
      shortPath = 'PASSPORT';
    }

    const nTips = tips[shortPath] || [];
    const title = nTips.length > 0 ? tips[shortPath][step] : '';

    const imgs = nTips.map((tip, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={i} style={{ textAlign: 'center' }}>
        <img alt="" src={`${path}/tip-${i + 1}.png`} />
      </div>
    ));
    const Dots = () => {
      // eslint-disable-next-line react/no-array-index-key
      return nTips.map((tip, i) => <li key={i} className={i === step ? classes.active : ''} />);
    };

    return (
      <div className={classNames(classes.wrapper, display)}>
        <ReactSwipe
          className={classes.swContainer}
          swipeOptions={swipeOptions}
          ref={(ref) => {
            this.reactSwipe = ref;
          }}
        >
          {imgs}
        </ReactSwipe>
        <div className="wp-b-tipsHeader">
          <h3>{title}</h3>
        </div>
        <ul className={classes.dots}>
          <Dots />
        </ul>
        {/* <Button onClick={this.handleClick} shadow large full label={buttonText} /> */}
      </div>
    );
  }
}

export default Tips;
