import React from 'react';
import PropTypes from 'prop-types';
import APIs from '@services/APIs';
import { Message } from '../components';

function redirect() {
  window.setTimeout(() => {
    APIs.cancel();
  }, 1500);
}
/**
 * Error 500
 */
export const Error500 = (props) => {
  return (
    <Message {...props} title="Oops, something unexpected happened. We’re redirecting you..." issue>
      <div>Please wait</div>
      {redirect()}
    </Message>
  );
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  return (
    <Message
      {...props}
      buttons={[
        {
          label: 'Back',
          variant: 'transparent'
          // onClick: () => document.location.reload()
        },
        {
          label: 'Done',
          shadow: true,
          onClick: () => document.location.reload()
        }
      ]}
      title="Your internet connection is not allowing us to continue"
      issue
    >
      <ol className="oli-style-success">
        <li>Try connecting to a different WiFi network</li>
        <li>Try turning off your WiFi and using your mobile data</li>
      </ol>
      <div>Once you’ve done this, come back to this page to continue your application.</div>
    </Message>
  );
};

/**
 * Timeout
 */
export const Timeout = (props) => {
  return (
    <Message {...props} title="Oops, session expired. We’re redirecting you..." issue={false}>
      <div>Please wait</div>
    </Message>
  );
};

/**
 * Device Exceptions
 * */

export const DeviceIncompatibleOpenChrome = ({ issue = true, ...restProps }) => {
  return (
    <Message
      issue={issue}
      {...restProps}
      title="You will need to open a different browser to proceed."
    >
      <div>Tap "Proceed" to continue.</div>
    </Message>
  );
};

DeviceIncompatibleOpenChrome.propTypes = {
  issue: PropTypes.bool
};
