const {
  VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE = 'Australian driver licence',
  VOI_FLOW_V2_PROOF_OF_AGE = 'Proof of age card',
  VOI_FLOW_V2_AUS_MEDICARE = 'Medicare card',
  VOI_FLOW_V2_AUS_AUTO_BIRTH = 'Birth certificate',
  VOI_FLOW_V2_AUS_CERT_CITIZENSHIP = 'Citizenship certificate',
  VOI_FLOW_V2_AUS_CERT_DESCENT = 'Descent certificate',
  VOI_FLOW_V2_AUS_CENTRELINK = 'Centrelink',
  VOI_FLOW_V2_AUS_DEFENCE_FORCE = 'Defence Force',
  VOI_FLOW_V2_AVAILABLE_DOCS1 = null,
  VOI_FLOW_V2_AVAILABLE_DOCS2 = null,
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS1 = null,
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS2 = null
} = process.env;

const defaultAvailableDocumentListPart1 = [
  {
    type: 'Driver Licence',
    title: VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'AUS_IMMICARD',
    title: 'ImmiCard',
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PROOF_OF_AGE',
    title: VOI_FLOW_V2_PROOF_OF_AGE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const defaultAvailableDocumentListPart2 = [
  {
    type: 'Medicare',
    title: VOI_FLOW_V2_AUS_MEDICARE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'AUS_AUTO_BIRTH',
    title: VOI_FLOW_V2_AUS_AUTO_BIRTH,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'CITIZENSHIP_CERT',
    title: VOI_FLOW_V2_AUS_CERT_CITIZENSHIP,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: false
  },
  {
    type: 'AUS_CERT_DESCENT',
    title: VOI_FLOW_V2_AUS_CERT_DESCENT,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: false
  },
  {
    type: 'Centrelink',
    title: VOI_FLOW_V2_AUS_CENTRELINK,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Defence Force ID',
    title: VOI_FLOW_V2_AUS_DEFENCE_FORCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const defaultOneDocConditionDocumentList = [
  {
    type: 'Driver Licence',
    title: VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PROOF_OF_AGE',
    title: VOI_FLOW_V2_PROOF_OF_AGE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const defaultAdditionalOneDocConditionDocumentList = [
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Medicare',
    title: VOI_FLOW_V2_AUS_MEDICARE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Defence force I.D',
    title: VOI_FLOW_V2_AUS_DEFENCE_FORCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

export const availableDocumentListPart1 = VOI_FLOW_V2_AVAILABLE_DOCS1
  ? [...VOI_FLOW_V2_AVAILABLE_DOCS1]
  : [...defaultAvailableDocumentListPart1];

export const availableDocumentListPart2 = VOI_FLOW_V2_AVAILABLE_DOCS2
  ? [...VOI_FLOW_V2_AVAILABLE_DOCS2]
  : [...defaultAvailableDocumentListPart2];

export const oneDocConditionDocumentList = VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS1
  ? [VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS1]
  : defaultOneDocConditionDocumentList;

export const oneDocConditionAdditionalDocumentList = VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS2
  ? [VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS2]
  : defaultAdditionalOneDocConditionDocumentList;
