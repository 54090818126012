import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { QuestionnaireForm } from '@components';

import APIs from '@js/services/APIs';

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.handleExit = this.handleExit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleExit() {
    const { onComplete } = this.props;
    onComplete();
  }

  handleSubmit(formData) {
    const { onComplete } = this.props;
    APIs.storeQuestionnaireAnswers(formData);
    onComplete();
  }

  getQuestionnaire() {
    const { questionnaire } = this.props;

    return questionnaire;
  }

  render() {
    const questionnaire = this.getQuestionnaire();
    const { schemas, uiSchemas } = questionnaire;

    return (
      <QuestionnaireForm
        schemas={schemas}
        uiSchemas={uiSchemas}
        onExit={() => this.handleExit()}
        onSubmit={(formData) => this.handleSubmit(formData)}
      />
    );
  }
}

Questionnaire.propTypes = {
  onComplete: PropTypes.func,
  questionnaire: PropTypes.object
};

export default connect(mapStateToProps, null)(Questionnaire);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
