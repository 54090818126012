import React, { Component } from 'react';
import PropTypes from 'prop-types';
import APIs from '@services/APIs';
import { setCookie, getCookie } from '@lib/Utils';
import { localizedString } from '@languages';
import { LoadingBar, Message } from '../../components';
import Prepare from '../../components/Contents/FaceScan/Prepare';
import { SelfiesTip, Face, TurnHead } from '../../components/Contents/AlternateFlow';

export default class AlternateFlow extends Component {
  static propTypes = {
    onNextStep: PropTypes.func,
    onGoBack: PropTypes.func
  };

  static defaultProps = {
    onNextStep: () => null
  };

  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      totalSteps: 4,
      smile: true,
      progressBar: 0,
      faceFile: null,
      isUploading: false,
      error: null
    };

    this.element = null;

    this.handleChange = this.handleChange.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  componentDidMount() {
    const random = Math.floor(Math.random() * 2);
    this.setState({ smile: !random });
  }

  /**
   * Handle change
   * @return {Void}
   */
  handleChange(e) {
    const { onNextStep } = this.props;
    const { smile, faceFile } = this.state;
    if (!e.target.files[0]) return;

    const imagefile = e.target.files[0];
    e.target.value = '';

    if (!faceFile) {
      this.setState(({ step }) => ({
        step: step + 1,
        faceFile: imagefile
      }));
      return;
    }
    const params = { face: faceFile, turnHead: imagefile };

    if (smile) {
      params.smile = 1;
    }

    const error = {
      component: Message,
      props: {
        issue: true,
        title: 'An error has occurred',
        children: 'Press "Try Again" to reattempt.',
        buttons: [
          {
            label: 'Try again',
            shadow: true,
            onClick: () =>
              this.setState({
                step: 1,
                faceFile: null,
                error: null
              })
          }
        ]
      }
    };

    this.setState({ isUploading: true });
    APIs.uploadSelfieImage(params, {
      before: () => this.setState({ progressBar: 0 }),
      onProgress: (width) => this.setState({ progressBar: width })
    })
      .then(({ status, msg, redirectTo }) => {
        // eslint-disable-next-line no-console
        console.log({ msg });
        if (status !== 'success') {
          this.setState({
            error,
            isUploading: false
          });
          return;
        }

        onNextStep({ completed: true, redirectTo });
      })
      .catch(() => {
        let attempt = parseInt(getCookie('retryAttempt'), 10) || 0;
        attempt += 1;
        setCookie('retryAttempt', attempt.toString(), 1);
        //---
        this.setState({ error, isUploading: false });
      });
  }

  /**
   * Go to the next step
   * @return {Void}
   */
  handleNextStep() {
    const { totalSteps, step } = this.state;
    const { onNextStep } = this.props;
    if (step < totalSteps - 1) {
      this.setState(({ step }) => ({ step: step + 1 }));
    } else {
      onNextStep({ completed: true });
    }
  }

  /**
   * Go back to the previous step
   * @return {Void}
   */
  handleGoBack() {
    const { onGoBack } = this.props;
    const { step } = this.state;
    if (step) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      onGoBack();
    }
  }

  render() {
    const { step, smile, isUploading, progressBar, error } = this.state;
    const { component: Error, props: errorProps } = error || {};
    return (
      <div>
        {Error && <Error {...errorProps} />}
        {isUploading && <LoadingBar title={localizedString('uploading')} width={progressBar} />}
        {!isUploading && !Error && (
          <div>
            {!step && <Prepare selfie onGoBack={this.handleGoBack} onStart={this.handleNextStep} />}
            {step === 1 && (
              <SelfiesTip onGoBack={this.handleGoBack} onNextStep={this.handleNextStep} />
            )}
            {step === 2 && <Face smile={smile} onCapture={() => this.element.click()} />}
            {step === 3 && <TurnHead onCapture={() => this.element.click()} />}
          </div>
        )}
        <input
          type="file"
          name="image"
          accept="image/*"
          capture="user"
          onChange={this.handleChange}
          ref={(ref) => {
            this.element = ref;
          }}
          style={{ opacity: 0, zIindex: 99 }}
        />
      </div>
    );
  }
}
