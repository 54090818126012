/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import '../../../styles/main.scss';

import {
  isWebRTCSupported,
  copyOpenSafari,
  isAndroidDevice,
  isBrowserPermitted,
  setCookie,
  getCookie
} from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { FaceScan } from '../../../_FLOW_V2_FLOW/containers';
import { Error500, InternetCut, DeviceIncompatibleOpenChrome, Timeout } from '../../errors';
import {
  // FaceScan,
  AlternateFlow,
  Success
} from '..';
import { Modal, LoadingSpinner, LoadingBar, Message } from '../../components';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      idType: 'PASSPORT',
      country: process.env.COUNTRY,
      tokenId: '',
      geolocation: '',
      cancelled: false,
      confirm: false,
      confirmFR: false,
      error: null,
      tenMinsLeft: false,
      compatible: true,
      completed: false,
      isLandscape: false,
      isProcessing: false,
      isUploading: false,
      uploadBar: 0,
      selfieFR: false,
      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      allowedCountries: ['AU', 'NZ']
    };

    this.input = null;

    this.handleComplete = this.handleComplete.bind(this);
    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  async componentDidMount() {
    const { isCompleted: completed } = this.props;
    this.setState({ completed });

    const { allowedCountries } = this.state;
    const { COUNTRIES, COUNTRY } = process.env;

    const { status: compatible, todo, os } = await isWebRTCSupported();

    // Init device compatible
    if (window.location.search === '?flow=alternate') {
      // for testing
      this.setState({ compatible, webrtc: { todo: 'ALT_FLOW', status: false } });
    } else {
      this.setState({ compatible, webrtc: { todo, status: compatible } });
    }

    if (os === 'iOS') {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {}
        }
      })
    );
    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            isOnline: true,
            onGoBack: this.handleInternetReconnect
          }
        }
      });
    });

    // Set country
    if (COUNTRIES) {
      APIs.country().then(({ country_code }) => {
        const country = allowedCountries.includes(country_code) ? country_code : COUNTRY;
        this.setState({ country });
      });
    } else {
      this.setState({ country: COUNTRY });
    }

    // Landscape
    const isLandscape = window.innerHeight < window.innerWidth;
    this.setState({ isLandscape });
    window.addEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false,
          error: {
            component: Timeout,
            props: {}
          }
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    window.removeEventListener('orientationchange', () => {
      this.setState(({ isLandscape }) => ({ isLandscape: !isLandscape }));
    });
  }

  /**
   * When all flows have been completed.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleComplete(state) {
    const { sessionId: id, lr, liveness } = state;
    const { REMOVE_ADDRESS_REVIEW = false, WSS_URL } = process.env;
    /**
     * Upload face scan video.
     */
    const data = { id, lr, actions: `Smile, Turn head ${lr ? 'left' : 'right'}` };
    if (liveness) {
      data.success = 1;
    }

    let axiosParams;
    if (WSS_URL.includes('liveness.idkit.io')) {
      this.setState({ isProcessing: true });
    } else {
      this.setState({ uploadBar: 0, isUploading: true });
      axiosParams = {
        before: () => this.setState({ uploadBar: 0, isUploading: true }),
        onProgress: (width) => this.setState({ uploadBar: width })
      };
    }

    APIs.uploadVideo(data, axiosParams, '/api/v4')
      .then(({ status, token, msg: error, action = null }) => {
        this.setState({ isProcessing: false, completed: true, isUploading: false });

        if (status !== 'success') {
          if (action === 'ALTERNATE') {
            this.setState({ webrtc: { todo: 'ALT_FLOW', status: false }, completed: false });
            return;
          }
          console.error('video upload failed', { data, error });
          throw new Error(error);
        }

        /**
         * Get face scan results.
         */
        APIs.verifyScans(token)
          .then(({ status, redirectTo }) => {
            if (status !== 'success') {
              console.error('face scan data failed');
              throw new Error(error);
            }

            setCookie('retryAsf', 'no', -10);
            setCookie('retry', null, -7);
            setCookie('retryCaptureWithVideoStream', null, -7);
            setCookie('retryAttempt', null, -7);
            setCookie('idCaptureAttempt', 0, -7);
            setCookie('detailMatch', 'false', -1);
            setCookie('_permission', null, -7);

            if (redirectTo) {
              setTimeout(() => {
                APIs.return();
              }, 3000);
              this.setState({ redirect: true, completed: true });
            } else {
              this.setState({ completed: true });
            }
          })
          .catch(() => {
            const error = {
              component: Error500,
              props: {
                onTryAgain: () => {
                  if (REMOVE_ADDRESS_REVIEW) {
                    this.setState({ step: 4, error: null });
                  } else {
                    this.setState({ step: 6, error: null });
                  }
                }
              }
            };
            this.setState({ error, isProcessing: false, isUploading: false });
          });
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              if (REMOVE_ADDRESS_REVIEW) {
                this.setState({ step: 4, error: null });
              } else {
                this.setState({ step: 6, error: null });
              }
            }
          }
        };
        this.setState({ error, isProcessing: false, isUploading: false });
      });
  }

  /**
   * Handle AlternateFlow complete
   *
   * @param {Object} state
   * @return {Void}
   */
  handleCompleteAlternate = ({ redirectTo }) => {
    setCookie('retryAsf', 'no', -10);
    setCookie('retry', null, -7);
    setCookie('retryCaptureWithVideoStream', null, -7);
    setCookie('retryAttempt', null, -7);
    setCookie('idCaptureAttempt', 0, -7);
    setCookie('detailMatch', 'false', -1);
    setCookie('_permission', null, -7);

    if (redirectTo) {
      setTimeout(() => {
        APIs.return();
      }, 3000);
      this.setState({ redirect: true, completed: true });
    } else {
      this.setState({ completed: true });
    }
  };

  /**
   * Go to next step.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleNextStep(state) {
    this.setState(({ step }) => ({ step: step + 1, ...state }));
  }

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    this.setState(({ step }) => ({ step: step - 1, selfieFR: false }));
  }

  /**
   * Handle internet reconnection
   *
   * @return {Void}
   */
  handleInternetReconnect = () => {
    this.setState(({ step }) => ({ step: step - 1, error: null }));
  };

  /**
   * Cancel the session.
   *
   * @return {Void}
   */
  handleExit() {
    this.setState({ confirm: false });
    this.handleGoBack();
  }

  handleFRGoBack = () => {
    const { REMOVE_ADDRESS_REVIEW = false } = process.env;
    const { idDetails = {} } = this.props;

    const reloaded = parseInt(getCookie('retryAttempt'), 10) || parseInt(getCookie('retry'), 10);
    if (reloaded) {
      this.setState({ confirm: true, confirmFR: true });
      return;
    }

    if (REMOVE_ADDRESS_REVIEW || (idDetails && idDetails.address)) {
      this.setState({ step: 1 });
    } else {
      this.handleGoBack();
    }
  };

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const {
      idType,
      tokenId,
      cancelled,
      confirm,
      confirmFR,
      error,
      tenMinsLeft,
      compatible,
      completed,
      isProcessing,
      isUploading,
      uploadBar,
      isLandscape,
      geolocation,
      country,
      modalError,
      webrtc = {},
      selfieFR,
      redirect
    } = this.state;
    const { todo, status: isWebRTC } = webrtc;
    const { component: Error, props: errorProps } = error || {};
    /**
     * Button states
     */
    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ confirm: false, confirmFR: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          setCookie('retry', null, -7);
          setCookie('retryAttempt', null, -7);
          this.setState({ step: 0, confirm: false, confirmFR: false });
          // this.handleGoBack();
        }
      }
    ];
    const openChromeBtns = [
      {
        label: localizedString('proceed'),
        full: true,
        onClick: () => {
          const { id } = document.body.dataset;
          document.location = `googlechrome://navigate?url=${document.location.href}${id}`;
          setTimeout(() => {
            this.setState({ compatible: true });
          }, 1000);
        }
      }
    ];
    const openSafariBtns = [
      {
        children: (
          <a className="link-inside-button" href="x-web-search://" target="_self">
            {localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_IOS')}
          </a>
        ),
        full: true,
        onClick: () => copyOpenSafari(this.input)
      }
    ];
    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];
    /**
     * Exceptions for device compatible.
     */
    if (!compatible) {
      if (todo === 'OPEN_SAFARI') {
        return (
          <Message
            title={localizedString('deviceIncompatible')}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {parse(localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS'))}
            <input
              readOnly
              className="b-hidden-input"
              ref={(ref) => {
                this.input = ref;
              }}
            />
          </Message>
        );
      }
      if (todo === 'NEED_ALT_FLOW') {
        const url = `${window.location.href}?flow=alternate`;
        window.location.replace(url);
      }
    }

    // Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome issue={false} buttons={openChromeBtns} />;
    }

    return (
      <div>
        {modalError && <Modal isOpen {...modalError} />}
        {Error && <Error {...errorProps} />}
        {isLandscape && <Message landscape />}
        {/* Pages */}
        {!Error && !completed && (
          <div>
            {isWebRTC && !selfieFR && (
              <FaceScan
                idType={idType}
                tokenId={tokenId}
                onNextStep={this.handleComplete}
                onGoBack={this.handleFRGoBack}
                onSelfie={() => this.setState({ selfieFR: true })}
                location={geolocation}
                countryCode={country}
              />
            )}
            {(!isWebRTC || selfieFR) && (
              <AlternateFlow
                onNextStep={this.handleCompleteAlternate}
                onGoBack={this.handleFRGoBack}
              />
            )}
          </div>
        )}
        {/* Exceptions */}
        {cancelled && (
          <Message logoFooter cancelled title="Session cancelled">
            {localizedString('sessionCancelledDesc1')}
            <br />
            <br />
            {localizedString('sessionCancelledDesc2')}
          </Message>
        )}
        {tenMinsLeft && (
          <Modal isOpen heading={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        {completed && <Success redirect={redirect} />}
        <Modal
          isOpen={confirm}
          heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')}
          description=""
          buttons={confirmBtns}
        >
          {confirmFR
            ? localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS')
            : localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_CAPTURE')}
        </Modal>
        {/* End exceptions */}
        {/* Loadings */}
        {isUploading && <LoadingBar heading={localizedString('uploading')} width={uploadBar} />}
        {isProcessing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}

        {this.renderLanguageSelection()}
      </div>
    );
  }
}

App.propTypes = {
  isCompleted: PropTypes.bool,
  idDetails: PropTypes.object
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information }) {
  return {
    idDetails: information.idDetails,
    addresses: information.addresses
  };
}
