import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Form from 'react-jsonschema-form';
import { validate } from '@lib/questionnaireCustomValidation';
import { transformErrors } from '@lib/questionnaireCustomErrors';

import { Button } from '@lib/components/v2/Form';
import { Header } from '@lib/components/v2/Page';

import { localizedString } from '@languages';
import classes from './Questionnaire.style.module.scss';

export default class Questionnaire extends Component {
  static propTypes = {
    schemas: PropTypes.object,
    uiSchemas: PropTypes.object,
    onSubmit: PropTypes.func,
    onExit: PropTypes.func
  };

  constructor(props) {
    super(props);

    const { schemas, uiSchemas } = props;
    const schemaKeys = Object.keys(schemas);
    const uiSchemaKeys = Object.keys(uiSchemas);

    this.state = {
      step: 0,
      formData: {},
      schemaKeys,
      uiSchemaKeys,
      stepSchema: schemas[schemaKeys[0]],
      stepUischema: uiSchemas[uiSchemaKeys[0]],
      showReview: false
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handlePreviousStep = this.handlePreviousStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleNextStep() {
    const { schemas, uiSchemas } = this.props;
    const { step, schemaKeys, uiSchemaKeys } = this.state;

    if (this.isLastStep()) {
      this.setState({ showReview: true });
      return;
    }

    const nextStep = step + 1;
    const nextStepSchema = schemas[schemaKeys[step + 1]];
    const nextStepUichema = uiSchemas[uiSchemaKeys[step + 1]];

    this.setState({
      step: nextStep,
      stepSchema: nextStepSchema,
      stepUischema: nextStepUichema
    });
  }

  handlePreviousStep() {
    const { schemas, uiSchemas } = this.props;
    const { step, schemaKeys, uiSchemaKeys } = this.state;
    if (this.isFirstStep()) {
      return;
    }

    this.setState({
      step: step - 1,
      stepSchema: schemas[schemaKeys[step - 1]],
      stepUischema: uiSchemas[uiSchemaKeys[step - 1]]
    });
  }

  handleChange({ formData }) {
    this.setState({ formData });
  }

  handleSubmit() {
    const { onSubmit } = this.props;
    const { formData } = this.state;
    onSubmit(formData);
  }

  handleReedit(formKey) {
    const { schemas, uiSchemas } = this.props;
    this.setState({
      showReview: false,
      stepSchema: schemas[formKey],
      stepUischema: uiSchemas[formKey]
    });
  }

  isFirstStep() {
    const { step } = this.state;
    return step === 0;
  }

  isLastStep() {
    const { step, schemaKeys } = this.state;

    return step === schemaKeys.length - 1;
  }

  showReviewContent() {
    const { uiSchemas } = this.props;
    const content = Object.keys(uiSchemas).map((formKey) => {
      const formReview = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const [key, value] of Object.entries(uiSchemas[formKey])) {
        if (Object.prototype.hasOwnProperty.call(value, 'ui:title')) {
          formReview.push(this.renderTitleAndAnswer(key, value));
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const [nestedKey, nestedValue] of Object.entries(value)) {
            const titleAndAnswer = this.renderTitleAndAnswer(nestedKey, nestedValue, key);
            if (!titleAndAnswer) {
              // eslint-disable-next-line no-continue
              continue;
            }
            formReview.push(titleAndAnswer);
          }
        }
      }

      return (
        <div className={classNames(classes.questionsGroup)} key={formKey}>
          <div className={classNames(classes.questions)}>{formReview}</div>
          <div className={classNames(classes.edit)}>
            <u
              onClick={() => {
                this.handleReedit(formKey);
              }}
            >
              Edit
            </u>
          </div>
        </div>
      );
    });

    return content;
  }

  renderTitleAndAnswer(key, value, outterKey = '') {
    const { formData } = this.state;
    let answer;

    if (outterKey) {
      if (!Object.prototype.hasOwnProperty.call(formData, outterKey)) {
        return null;
      }

      if (!Object.prototype.hasOwnProperty.call(formData[outterKey], key)) {
        return null;
      }

      answer = formData[outterKey][key];
    } else {
      answer = formData[key];
    }

    if (!answer) {
      return null;
    }

    const title = value['ui:title'];

    return (
      <div key={outterKey + key} className={classNames(classes.question)}>
        <div className={classNames(classes.title)}>{title}</div>
        <div className={classNames(classes.answer)}>
          {Array.isArray(answer) ? answer.join(', ') : answer}
        </div>
      </div>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  renderImportantInfo() {
    return (
      <div className={classNames(classes.importantInfo)}>
        <span>{localizedString('importantInformation')}</span>
        <p>
          {localizedString('collectingYourPersonalInformation')}{' '}
          <a
            target="_blank"
            rel="noreferrer noopener"
            href="https://www.nab.com.au/common/privacy-policy"
          >
            <u>{localizedString('privacyPolicy')}</u>
          </a>
          . {localizedString('nabPrivacyPolicy')}.
        </p>
      </div>
    );
  }

  render() {
    const { onExit } = this.props;
    const { formData, showReview, stepSchema, stepUischema } = this.state;

    const widgets = {
      CheckboxesWidget: CustomerCheckboxes
    };

    return (
      <div className={classNames(classes.wrapper, 'b-container')}>
        <Header title="Questionnaire" />
        {!showReview && (
          <Form
            showErrorList={false}
            widgets={widgets}
            validate={validate}
            transformErrors={transformErrors}
            schema={stepSchema}
            uiSchema={stepUischema}
            formData={formData}
            onChange={this.handleChange}
            onSubmit={this.handleNextStep}
            noHtml5Validate
          >
            <div className={classes.footer}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.buttons}>
                  {this.isFirstStep() && (
                    <Button type="button" onClick={onExit} label="Exit" variant="outline" />
                  )}

                  {!this.isFirstStep() && (
                    <Button
                      type="button"
                      onClick={this.handlePreviousStep}
                      label="Back"
                      variant="outline"
                    />
                  )}

                  {!this.isLastStep() && <Button type="submit" label="Next" />}

                  {this.isLastStep() && <Button type="submit" label="Preview" />}
                </div>
              </div>
            </div>
          </Form>
        )}
        {showReview && (
          <div className={classNames(classes.review)}>
            <div className={classNames(classes.header)}>
              <h3>Review and submit</h3>
            </div>
            <div className={classNames(classes.questionsGroups)}>
              {this.showReviewContent()}
              {this.renderImportantInfo()}
            </div>
            <div className={classes.footer}>
              <div className={classes.buttonsWrapper}>
                <div className={classes.buttons}>
                  <Button
                    type="button"
                    label="Back"
                    variant="outline"
                    onClick={() => {
                      this.setState({ showReview: false });
                    }}
                  />
                  <Button type="button" label="Submit" onClick={this.handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

function CustomerCheckboxes(props) {
  const { id, disabled, options, value, autofocus, readonly, onChange } = props;
  const { enumOptions, enumDisabled, inline } = options;

  const selectValue = (value, selected, all) => {
    const at = all.indexOf(value);
    const updated = selected.slice(0, at).concat(value, selected.slice(at));
    // As inserting values at predefined index positions doesn't work with empty
    // arrays, we need to reorder the updated selection to match the initial order
    return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
  };

  const deselectValue = (value, selected) => {
    return selected.filter((v) => v !== value);
  };

  return (
    <div className="checkboxes" id={id}>
      {enumOptions.map((option, index) => {
        const checked = value.indexOf(option.value) !== -1;
        const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        const disabledCls = disabled || itemDisabled || readonly ? 'disabled' : '';
        const checkbox = (
          <span>
            <input
              type="checkbox"
              id={`${id}_${index}`}
              checked={checked}
              disabled={disabled || itemDisabled || readonly}
              autoFocus={autofocus && index === 0}
              onChange={(event) => {
                const all = enumOptions.map(({ value }) => value);
                if (event.target.checked) {
                  onChange(selectValue(option.value, value, all));
                } else {
                  onChange(deselectValue(option.value, value));
                }
              }}
            />
            <span>&nbsp;{option.label}</span>
          </span>
        );
        return inline ? (
          <label key={option.label} className={`checkbox-inline ${disabledCls}`}>
            {checkbox}
          </label>
        ) : (
          <div key={option.label} className={`checkbox ${disabledCls}`}>
            <label>{checkbox}</label>
          </div>
        );
      })}
    </div>
  );
}

CustomerCheckboxes.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  value: PropTypes.any,
  autofocus: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func
};
