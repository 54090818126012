import axios from 'axios';
import { getCountryISO3 } from '@services/Address/country-iso-2-to-3';

const ADDRESS_FINDER_ALLOWED_COUNTRIES = ['AU', 'NZ'];
const BASE_URL = process.env.API_URL || '/api/v1';

export default {
  getName,
  find,
  verify,
  canHandleCountry,
  mapRemoteList,
  format
};

function getName() {
  return 'addressFinder';
}

function canHandleCountry(country) {
  return ADDRESS_FINDER_ALLOWED_COUNTRIES.includes(country);
}

async function find({ address, country }) {
  const payload = {
    address,
    country
  };

  const source = axios.CancelToken.source();

  try {
    const { data } = await axios.post(`${BASE_URL}/address/autocomplete/${getName()}`, payload, {
      cancelToken: source.token
    });
    return mapRemoteList(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function mapRemoteList(data) {
  return data.completions.map((item) => ({
    text: item.full_address
  }));
}

async function verify({ address, country }) {
  const payload = {
    address
  };
  const source = axios.CancelToken.source();
  return axios
    .post(`${BASE_URL}/address/validate/${getName()}`, payload, { cancelToken: source.token })
    .then(({ data }) => {
      return format(address, country, data);
    })
    .catch((err) => {
      console.error(err);
      return format(address, country);
    });
}

function format(address, country, cleanseData = null) {
  const countryCode = country?.length === 2 ? getCountryISO3(country) : country;

  if (!cleanseData) {
    throw new Error('Cannot validate address. No results found');
  }

  // If it is Address Finder call and Address entered is not valid
  if (!cleanseData.matched) {
    throw new Error('Address entered is not valid');
  }

  let { address: addrObject } = cleanseData;
  if (!addrObject) {
    addrObject = cleanseData;
  }

  const mappedData = {
    fullAddress: address,
    homeAddress: address,
    addressLine1: addrObject.address_line_1 || '',
    addressLine2: addrObject.address_line_2 || '',
    unit_number: addrObject.unit_identifier || '',
    street_number: addrObject.street_number_1 || '',
    street_name: addrObject.street_name || '',
    street_type: addrObject.street_type || '',
    suburb: addrObject.suburb || addrObject.locality_name || '',
    city: addrObject.city || '',
    state_territory: addrObject.state_territory || '',
    postcode: addrObject.postcode || '',
    country: countryCode,
    address_api: getName()
  };

  return { ...mappedData, provider: getName() };
}
