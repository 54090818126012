import React, { Component } from 'react';
import PropTypes from 'prop-types';
import draftToHtml from 'draftjs-to-html';
import { connect } from 'react-redux';
import APIs from '@services/APIs';
import PrivacyContent from '@lib/components/v2/PrivacyFlowV2';
import { getLocalizedIdvcContent } from '@languages';

class Privacy extends Component {
  static propTypes = {
    flowType: PropTypes.string,
    onAccept: PropTypes.func,
    onExit: PropTypes.func,
    appConfig: PropTypes.object,
    onSelectLanguage: PropTypes.func
  };

  static defaultProps = {
    onAccept: () => null,
    onExit: () => null
  };

  componentDidMount() {
    APIs.status('termsAndCondition');
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { HIDE_PRIVACY_CHECKBOX = false } = process.env;
    const { flowType, appConfig, onAccept, onExit, onSelectLanguage } = this.props;
    const props = {
      privacyContent: null
    };
    if (appConfig.terms) {
      props.privacyContent = draftToHtml(getLocalizedIdvcContent(appConfig.terms));
    }
    return (
      <PrivacyContent
        {...props}
        hidePrivacyCheckBox={HIDE_PRIVACY_CHECKBOX}
        onAccept={() => onAccept()}
        onExit={() => onExit()}
        onSelectLanguage={() => onSelectLanguage()}
        flowType={flowType}
      />
    );
  }
}

export default connect(mapStateToProps, null)(Privacy);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
