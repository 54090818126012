import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LANGUAGES } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import classes from './Header.style.module.scss';

const { ENABLE_ONE_DOC_CONDITION = false, DEFAULT_LANGUAGE = 'en' } = process.env;

const Header = ({ hideLogo, isDark, onSelectLanguage }) => {
  const { MULTI_LANGUAGE } = process.env;
  const finalLogo = useLogo();
  const language = document.body.dataset.language || DEFAULT_LANGUAGE;
  const languageItem = LANGUAGES.find((item) => item.name === language);

  let logoClassName = 'img-container';
  if (ENABLE_ONE_DOC_CONDITION) {
    logoClassName = 'img-container-one-doc';
  }

  return (
    <header className={classNames(classes.warpper, 'clearfix', isDark && classes.isDark)}>
      {!hideLogo && (
        <div className={classes[logoClassName]} data-testid="header-title">
          <img alt="" src={finalLogo} />
        </div>
      )}

      {MULTI_LANGUAGE && onSelectLanguage && (
        <div
          onClick={onSelectLanguage}
          className={classes['language-chooser']}
          data-testid="header-language"
        >
          {languageItem ? languageItem.title : 'English'}
          <img alt="" src="images/icons/png/forward-videoid.png" />
        </div>
      )}
    </header>
  );
};

Header.propTypes = {
  /* If its `true` the header position will be fixed */
  isDark: PropTypes.bool,
  hideLogo: PropTypes.bool,
  onSelectLanguage: PropTypes.func
};

Header.defaultProps = {
  hideLogo: false
};

export default Header;
