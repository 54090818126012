import axios from 'axios';

const API_URL = process.env.API_URL || '/api/v1';
const POLLING_INTERVAL_IN_SECONDS = 10;

const getCardStorageStatus = ({ transactionToken }) => {
  return axios.get(`${API_URL}/data/storage/status/${transactionToken}`).then(({ data }) => {
    if (data.status === 'error') {
      throw new Error(data.message || 'Unexpected Error');
    }
    return data;
  });
};

export const waitUntilLastCardStorageIsReady = async ({ transactionToken }) => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      try {
        const { status } = await getCardStorageStatus({ transactionToken });
        if (status === 'done') {
          resolve();
          clearInterval(interval);
        } else if (status === 'error') {
          throw new Error('Got and error checking storage status');
        }
      } catch (error) {
        clearInterval(interval);
        reject(error);
      }
    }, POLLING_INTERVAL_IN_SECONDS * 1000);
  });
};
