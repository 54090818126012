import { useSelector } from 'react-redux';

export const useLogo = ({ isSuccessScreen = false } = {}) => {
  const { APP_LOGO_SUCCESS, APP_LOGO } = process.env;
  const logo = useSelector(({ appConfig }) => appConfig?.logo);

  return logo || `images/logos/${(isSuccessScreen ? APP_LOGO_SUCCESS : APP_LOGO) || APP_LOGO}`;
};

export const withLogoHook = (Component) => {
  return function WrappedComponent(props) {
    const finalLogo = useLogo();
    return <Component {...props} finalLogo={finalLogo} />;
  };
};
