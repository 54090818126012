import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { QuestionnaireForm } from '@FLOW_V2_FLOW/components';

import APIs from '@services/APIs';

class Questionnaire extends Component {
  constructor(props) {
    super(props);

    this.handleExit = this.handleExit.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleExit() {
    const { onComplete } = this.props;
    onComplete();
  }

  handleSubmit(formData) {
    const { onComplete } = this.props;
    APIs.storeQuestionnaireAnswers(formData);
    onComplete();
  }

  getQuestionnaire() {
    const { questionnaire } = this.props;

    return questionnaire;
  }

  render() {
    const questionnaire = this.getQuestionnaire();
    const { schemas, uiSchemas } = questionnaire;

    return (
      <div>
        <QuestionnaireForm
          schemas={schemas}
          uiSchemas={uiSchemas}
          onExit={() => this.handleExit()}
          onSubmit={(formData) => this.handleSubmit(formData)}
        />
      </div>
    );
  }
}

Questionnaire.propTypes = {
  onComplete: PropTypes.func,
  questionnaire: PropTypes.object
};

export default connect(null, null)(Questionnaire);
