import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class CustomCheckboxes extends Component {
  render() {
    const { id, disabled, options, value, autofocus, readonly, onChange } = this.props;
    const { enumOptions, enumDisabled, inline } = options;

    const selectValue = (value, selected, all) => {
      const at = all.indexOf(value);
      const updated = selected.slice(0, at).concat(value, selected.slice(at));
      // As inserting values at predefined index positions doesn't work with empty
      // arrays, we need to reorder the updated selection to match the initial order
      return updated.sort((a, b) => all.indexOf(a) > all.indexOf(b));
    };

    const deselectValue = (value, selected) => {
      return selected.filter((v) => v !== value);
    };

    return (
      <div className="checkboxes" id={id}>
        {enumOptions.map((option, index) => {
          const checked = value.indexOf(option.value) !== -1;
          const itemDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
          const disabledCls = disabled || itemDisabled || readonly ? 'disabled' : '';
          const checkbox = (
            <span>
              <input
                type="checkbox"
                id={`${id}_${index}`}
                checked={checked}
                disabled={disabled || itemDisabled || readonly}
                autoFocus={autofocus && index === 0}
                onChange={(event) => {
                  const all = enumOptions.map(({ value }) => value);
                  if (event.target.checked) {
                    onChange(selectValue(option.value, value, all));
                  } else {
                    onChange(deselectValue(option.value, value));
                  }
                }}
              />
              <span>&nbsp;{option.label}</span>
            </span>
          );
          return inline ? (
            <label key={option.label} className={`checkbox-inline ${disabledCls}`}>
              {checkbox}
            </label>
          ) : (
            <div key={option.label} className={`checkbox ${disabledCls}`}>
              <label>{checkbox}</label>
            </div>
          );
        })}
      </div>
    );
  }
}

CustomCheckboxes.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.object,
  value: PropTypes.any,
  autofocus: PropTypes.bool,
  readonly: PropTypes.bool,
  onChange: PropTypes.func
};
