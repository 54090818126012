import axios from 'axios';

/**
 * SMS Service
 */
export default class SMS {
  static API_URL = process.env.API_URL;

  /**
   * Make a request to verify address.
   * @return {Promise}
   * @param data
   */
  static send(data) {
    const { CancelToken } = axios;
    const source = CancelToken.source();
    // When internet cut
    window.addEventListener('offline', () => {
      source.cancel('Operation canceled by the user.');
    });

    const transToken = document.querySelector('body').getAttribute('data-id');

    return new Promise((resolve, reject) => {
      axios
        .post(`${this.API_URL}/sms/resend/${transToken}`, data, { cancelToken: source.token })
        .then(({ data }) => resolve(data))
        .catch((err) => reject(err));
    });
  }
}
