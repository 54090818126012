import React from 'react';
import PropTypes from 'prop-types';
import { localizedString } from '@languages';
import { Message } from '../../..';
import classes from './Face.style.module.scss';

const Face = ({ onCapture, smile }) => {
  const buttons = [{ label: 'Capture', large: false, onClick: onCapture }];
  const img = (
    <img alt="" src={`images/tips/alternate-flow/${smile ? 'smile' : 'without-smile'}.jpg`} />
  );
  return (
    <Message buttons={buttons} className={classes.wrapper}>
      {smile ? (
        <div className={classes.content}>
          <h3 className="b-title">{localizedString('takeASelfieWithABigSmile')}</h3>
          <ul>
            <li>
              {localizedString('lookDirectlyAtCameraGiveA')}
              <b>{localizedString('bigSmileLowerCase')}</b>
              {localizedString('withYourWholeFaceInViewAndTakePhoto')}
            </li>
          </ul>
          {img}
        </div>
      ) : (
        <div className={classes.content}>
          <h3 className="b-title">{localizedString('takeASelfieWithoutASmile')}</h3>
          <ul>
            <li>{localizedString('lookDirectlyAtCameraWithYourWholeFaceInViewAndTakePhoto')}</li>
          </ul>
          {img}
        </div>
      )}
    </Message>
  );
};

Face.propTypes = {
  onCapture: PropTypes.func,
  smile: PropTypes.bool
};

Face.defaultProps = {
  smile: false
};

export default Face;
