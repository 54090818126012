import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import { localizedString } from '@languages';

export const getResidentalAddressLabel = () => {
  const { FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 } = process.env;

  if (FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2) {
    if (FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2 === 'AU') {
      return 'Australian residential address';
    }
    return `${localizedString('residentalAddress')} (${getCountryLabelFromIso2Code(
      FLOW_V2_FORCE_ADDRESS_VALIDATION_COUNTRY_ISO2
    )})`;
  }

  return localizedString('residentalAddress');
};
