import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-mobile-datepicker';
import { isAndroidDevice, dateToString, reverseDateFormatSF } from '@lib/Utils';
import { localizedString } from '@languages';
import classes from './DatePicker.style.module.scss';
import { Input } from '../..';

/**
 * A wrapper around `react-mobile-datepicker` with `Input` component.
 */
export default class DatePicker extends Component {
  static propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    hasError: PropTypes.string
  };

  static defaultProps = {
    className: '',
    value: '',
    name: '',
    id: '',
    label: '',
    onChange: () => null
  };

  constructor(props) {
    super(props);

    this.state = {
      value: props.value,
      isOpen: false
    };

    this.handleSelect = this.handleSelect.bind(this);
  }

  /**
   * Handle the datepicker select
   * @param {Date} date
   * @return {Void}
   */
  handleSelect(date) {
    const { onChange } = this.props;
    const value = dateToString(date, true);
    this.setState({ value, isOpen: false });
    onChange(value);
  }

  /**
   * Render the component's markup
   * @return {ReactElement}
   */
  render() {
    const { isOpen, value } = this.state;
    const { value: defaultValue, className, onChange, hasError, ...restProps } = this.props;
    const theme = isAndroidDevice() ? 'android' : 'ios';
    const datePickerProps = {
      theme,
      isOpen,
      confirmText: localizedString('select'),
      cancelText: localizedString('cancel'),
      showFormat: localizedString('dateTimeFormat'),
      min: new Date(1900, 1, 1),
      value: reverseDateFormatSF(value, true)
    };
    const dateConfig = ['D', 'M', 'YYYY'];

    return (
      <div className={classes.wrapper}>
        <div className={className} onClick={() => this.setState({ isOpen: true })}>
          <Input readOnly hasError={hasError} value={value} {...restProps} />
        </div>
        <ReactDatePicker
          dateFormat={dateConfig}
          onSelect={this.handleSelect}
          onCancel={() => this.setState({ isOpen: false })}
          {...datePickerProps}
        />
        <span onClick={() => this.setState({ isOpen: true })} className={classes.icon}>
          <img alt="" src="images/icons/png/datepicker.png" />
        </span>
      </div>
    );
  }
}
