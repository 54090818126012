import { localizedString } from '@languages';

const Model = {
  name: {
    isRequired: localizedString('pleaseEnterFirstName'),
    isRegexMatch: {
      message: localizedString('lettersAndSymbolsOnly'),
      regex: /^([a-zA-Z0-9 /`‘’'-]+)$/
    }
  },
  reference: {
    isRequired: localizedString('enterReferenceNumber'),
    isRegexMatch: {
      message: localizedString('lettersAndSymbolsOnly'),
      regex: /^([a-zA-Z0-9 /.`‘’'-]+)$/
    }
  }
};

const { DETAIL_MATCH = [] } = process.env;
const nModel = {};
Object.keys(Model).forEach((key) => {
  if (DETAIL_MATCH.includes(key)) {
    nModel[key] = Model[key];
  }
});

export default nModel;
